import * as React from "react";
import { Box, Typography, Link, Button } from "@mui/material";
import Layout from "../components/Layout";
import { MetaData } from "../components/common/meta";
import { PageProps } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { projectBox, projectBoxLogo } from "../components/Index/Investments";
import { StaticImage } from "gatsby-plugin-image";
import { Link as RouterLink } from "gatsby";
import ArrowComponent from "../components/thePit/Arrow";

import Arrow from "../images/arrow-right.inline.svg";
import Calendar from "../images/calendar.inline.svg";
import Location from "../images/location.inline.svg";
import c1 from "../images/chronicle/c1.svg";
import c2 from "../images/chronicle/c2.svg";
import c3 from "../images/chronicle/c3.svg";
import c4 from "../images/chronicle/c4.svg";

import pit from "../images/xhack/pit.png";
import sideBrand from "../images/lineBrand.svg";
import xh from "../images/xhack.svg";
import Twitter from "../images/twitter.inline.svg";

import avalanche from "../images/xhack/avalanche.svg";
import solana from "../images/xhack/solana.svg";
import polygon from "../images/xhack/polygon.svg";
import algorand from "../images/xhack/algorand.svg";
import celo from "../images/xhack/celo.svg";
import acala from "../images/xhack/acala.svg";
import near from "../images/xhack/near.svg";
import binance from "../images/xhack/binance.svg";
import fantom from "../images/xhack/fantom.svg";
import neon from "../images/xhack/neon.svg";
import oasis from "../images/xhack/oasis.svg";
import aptos from "../images/xhack/aptos.svg";
import sui from "../images/xhack/sui.svg";
import injective from "../images/xhack/injective.svg";
import jump from "../images/xhack/jump.svg";
import pyth from "../images/xhack/pyth.svg";
import ftx from "../images/xhack/ftx.svg";
import raydium from "../images/xhack/raydium.svg";
import backpack from "../images/xhack/backpack.svg";
import hashflow from "../images/xhack/hashflow.svg";
import friktion from "../images/xhack/friktion.svg";
import raindrop from "../images/xhack/raindrop.svg";
import nexa from "../images/xhack/nexa.svg";
import atlas from "../images/xhack/atlas.svg";
import trailofbits from "../images/xhack/trailofbits.svg";
import certik from "../images/xhack/certik.svg";
import klaytn from "../images/xhack/klaytn.svg";

import otterSec from "../images/xhack/otterSec.svg";
import metaplex from "../images/index/metaplex.svg";
import lido from "../images/xhack/lido.svg";
import encode from "../images/xhack/encode.svg";
import mantaNetwork from "../images/xhack/mantaNetwork.svg";
import euler from "../images/xhack/euler.svg";
import steamDao from "../images/xhack/steamDao.svg";
import zebek from "../images/xhack/zebek.svg";
import automata from "../images/xhack/automata.svg";
import randLabs from "../images/xhack/randLabs.svg";
import lava from "../images/xhack/lava.svg";
import magpie from "../images/xhack/magpie.svg";
import monad from "../images/xhack/monad.svg";
import vrrb from "../images/xhack/vrrb.svg";
import alephim from "../images/xhack/alephim.svg";
import allbridge from "../images/xhack/allbridge.svg";
import c2k from "../images/xhack/c2k.svg";
import fenwick from "../images/xhack/fenwick.svg";
import gfx from "../images/xhack/gfx.svg";
import imperator from "../images/xhack/imperator.svg";
import lyra from "../images/xhack/lyra.svg";
import portalcomputer from "../images/xhack/portalcompute.svg";
import prime from "../images/xhack/prime.svg";
import protocollabs from "../images/xhack/protocollabs.svg";
import rushdown from "../images/xhack/rushdown.svg";
import satori from "../images/xhack/satori.svg";
import transpose from "../images/xhack/transpose.svg";
import xpla from "../images/xhack/xpla.svg";
import moonbeam from "../images/xhack/moonbeam.svg";
import coherence from "../images/xhack/coherence.svg";
import dialect from "../images/xhack/dialect.svg";
import moonlet from "../images/xhack/moonlet.svg";
import fracture from "../images/xhack/fracture.svg";
import triton from "../images/xhack/triton.svg";
import tsunami from "../images/xhack/tsunami.svg";
import bnbchain from "../images/xhack/bnbchain.svg";
import consensys from "../images/xhack/consensys.svg";
import cupcake from "../images/xhack/cupcake.svg";
import figment from "../images/xhack/figment.svg";
import galaxy from "../images/xhack/galaxy.svg";
import hxro from "../images/xhack/hxro.svg";
import notifin from "../images/xhack/notifin.svg";
import ratio from "../images/xhack/ratio.svg";
import swim from "../images/xhack/swim.svg";
import clockwork from "../images/xhack/clockwork.svg";
import zellic from "../images/xhack/zellic.svg";

export interface Project {
  url: string;
  name: string;
  logo: string;
}

const projects = [
  {
    logo: acala,
    name: "Acala",
    url: "https://acala.network/",
  },
  {
    logo: algorand,
    name: "Algorand",
    url: "https://www.algorand.com/",
  },
  {
    logo: aptos,
    name: "Aptos",
    url: "https://aptoslabs.com/",
  },
  {
    logo: avalanche,
    name: "Avalanche",
    url: "https://www.avax.network/",
  },
  {
    logo: bnbchain,
    name: "BNB Chain",
    url: "https://twitter.com/BNBCHAIN",
  },
  {
    logo: celo,
    name: "Celo",
    url: "https://celo.org/",
  },
  {
    logo: consensys,
    name: "Consensys",
    url: "https://twitter.com/ConsenSys",
  },
  {
    logo: fantom,
    name: "Fantom",
    url: "https://fantom.foundation/",
  },
  {
    logo: injective,
    name: "Injective",
    url: "https://injective.com/",
  },
  {
    logo: klaytn,
    name: "Klaytn",
    url: "https://www.klaytn.foundation/",
  },

  {
    logo: moonbeam,
    name: "Moonbeam",
    url: "https://moonbeam.network/",
  },
  {
    logo: monad,
    name: "Monad",
    url: "https://www.monad.xyz/",
  },
  {
    logo: near,
    name: "Near",
    url: "https://near.org/",
  },
  {
    logo: neon,
    name: "Neon",
    url: "https://neon-labs.org/",
  },
  {
    logo: oasis,
    name: "Oasis Labs",
    url: "https://www.oasislabs.com/",
  },
  {
    logo: polygon,
    name: "Polygon",
    url: "https://polygon.technology/",
  },
  {
    logo: solana,
    name: "Solana",
    url: "https://solana.com/",
  },

  {
    logo: sui,
    name: "Sui",
    url: "https://sui.io/",
  },
  {
    logo: vrrb,
    name: "VRRB",
    url: "https://www.vrrb.io/",
  },
  {
    logo: xpla,
    name: "Xpla",
    url: "https://xpla.io/",
  },
];

const projects2 = [
  {
    logo: ftx,
    name: "FTX",
    url: "https://ftx.com/en",
  },
  {
    logo: jump,
    name: "Jump_",
    url: "https://jumpcrypto.com/",
  },
  {
    logo: pyth,
    name: "Pyth",
    url: "https://pyth.network/",
  },
];

const projects3 = [
  {
    logo: alephim,
    name: "aleph.im",
    url: "https://aleph.im/#/",
  },
  {
    logo: allbridge,
    name: "Allbridge",
    url: "https://allbridge.io/",
  },
  {
    logo: automata,
    name: "Automata",
    url: "https://www.ata.network/",
  },
  {
    logo: atlas,
    name: "Atlas DEX",
    url: "https://atlasdex.finance/",
  },
  {
    logo: backpack,
    name: "Backpack",
    url: "https://www.backpack.app/",
  },
  {
    logo: c2k,
    name: "C2X",
    url: "https://c2x.world/",
  },
  {
    logo: certik,
    name: "Certik",
    url: "https://www.certik.com/",
  },
  {
    logo: clockwork,
    name: "Clockwork",
    url: "https://www.clockwork.xyz/",
  },
  {
    logo: coherence,
    name: "Coherence",
    url: "https://www.coherence.finance/",
  },
  {
    logo: cupcake,
    name: "Cupcake",
    url: "https://cupcake.com/",
  },
  {
    logo: dialect,
    name: "Dialect",
    url: "https://www.dialect.to/",
  },
  {
    logo: encode,
    name: "Encode",
    url: "https://www.encode.club/",
  },
  {
    logo: euler,
    name: "Euler",
    url: "https://www.euler.finance/",
  },
  {
    logo: fenwick,
    name: "Fenwick",
    url: "https://www.fenwick.com/",
  },
  {
    logo: figment,
    name: "Figment",
    url: "https://www.figment.io/",
  },
  {
    logo: friktion,
    name: "Friktion",
    url: "https://friktion.fi/",
  },
  {
    logo: galaxy,
    name: "Galaxy",
    url: "https://www.galaxy.com/",
  },
  {
    logo: gfx,
    name: "GooseFX",
    url: "https://www.goosefx.io/",
  },
  {
    logo: hashflow,
    name: "Hashflow",
    url: "https://www.hashflow.com/",
  },
  {
    logo: hxro,
    name: "HXRO",
    url: "https://www.hxro.network/",
  },
  {
    logo: imperator,
    name: "Imperator",
    url: "https://imperator.co/",
  },
  {
    logo: lava,
    name: "Lava",
    url: "",
  },
  {
    logo: lido,
    name: "Lido",
    url: "https://lido.fi/",
  },
  {
    logo: lyra,
    name: "Lyra",
    url: "https://www.lyra.finance/",
  },
  {
    logo: magpie,
    name: "Magpie",
    url: "https://www.magpiefi.xyz/",
  },
  {
    logo: mantaNetwork,
    name: "Manta Network",
    url: "https://www.manta.network/",
  },
  {
    logo: metaplex,
    name: "Metaplex",
    url: "https://www.metaplex.com/",
  },
  {
    logo: moonlet,
    name: "Moonlet",
    url: "https://moonlet.io/",
  },
  {
    logo: nexa,
    name: "Nexa",
    url: "",
  },
  {
    logo: notifin,
    name: "Notifi Network",
    url: "https://notifi.network/",
  },
  {
    logo: otterSec,
    name: "OtterSec",
    url: "https://osec.io/",
  },
  {
    logo: portalcomputer,
    name: "Portal Compute",
    url: "https://www.portalcompute.com/",
  },
  {
    logo: prime,
    name: "Prime",
    url: "",
  },
  {
    logo: protocollabs,
    name: "Protocal Labs",
    url: "https://protocol.ai/",
  },
  {
    logo: raindrop,
    name: "Raindrop IO",
    url: "https://long-banana.gitbook.io/raindrops/",
  },
  {
    logo: randLabs,
    name: "Rand Labs",
    url: "https://randlabs.io/",
  },
  {
    logo: ratio,
    name: "Ratio",
    url: "https://ratio.finance/",
  },
  {
    logo: raydium,
    name: "Raydium",
    url: "https://raydium.io/",
  },
  {
    logo: rushdown,
    name: "Rushdown Revolt",
    url: "https://www.rushdownrevolt.com/",
  },
  {
    logo: satori,
    name: "Satori",
    url: "https://satori.finance/",
  },
  {
    logo: steamDao,
    name: "Stems DAO",
    url: "https://www.stemsdao.com/",
  },
  {
    logo: swim,
    name: "Swim",
    url: "https://swim.io/",
  },
  {
    logo: fracture,
    name: "The Fracture",
    url: "https://thefracture.art/",
  },

  {
    logo: trailofbits,
    name: "Trail of Bits",
    url: "https://www.trailofbits.com/",
  },
  {
    logo: transpose,
    name: "Transpose",
    url: "https://www.transpose.io/",
  },
  {
    logo: triton,
    name: "Triton One",
    url: "https://www.triton.one/#/",
  },
  {
    logo: tsunami,
    name: "Tsunami",
    url: "https://tsunami.finance/",
  },
  {
    logo: zebek,
    name: "Zebec",
    url: "https://zebec.io/",
  },
  {
    logo: zellic,
    name: "Zellic",
    url: "https://www.zellic.io/",
  },
];

const cardStyle = {
  backgroundColor: "#091A3D",
  border: "1px solid #4B608D",
  px: { xs: 3, md: 4 },
  py: { xs: 3, md: 6 },
  maxWidth: { md: 427 },
  position: "relative",
};

const cardIndex = {
  position: "absolute",
  right: 0,
  top: 0,
  backgroundColor: "#1CE7C2",
  color: "#0D2352",
  width: 80,
  height: 40,
  textAlign: "center",
  lineHeight: "40px",
};

const cell = {
  background: "#0C1F47",
  border: "1px solid #4B608D",
  padding: "0 10px",
  textAlign: "center",
  height: 80,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: ".3s ease-in-out",
  "& svg": {
    mr: 1.3,
  },
  "&.MuiLink-root:hover": {
    boxShadow: "0 0 10px 0 rgba(75,96,141,.5)",
  },
};

const box = {
  background: "#0C1F47",
  border: "1px solid #4B608D",
  padding: "20px 10px",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& p": {
    fontSize: 20,
    lineHeight: 1.2,
    fontFamily: ["Poppins", "Helvetica", "Arial"].join(","),
  },
};

const card = {
  background: "#0C1F47",
  border: "1px solid #4B608D",
  padding: 1.6,
  aspectRatio: "1/1",
  position: "relative",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  overflow: "hidden",
  m: { xs: "0 auto 15px", md: 0 },
  "& h6": {
    color: "#1CE7C2",
    mb: 0.5,
    position: "relative",
  },
  "& a": {
    position: "relative",
    display: "block",
  },
  "& p": {
    fontSize: 13,
    position: "relative",
    "& span": {
      display: "block",
      "&:not(:last-of-type):after": {
        content: "','",
      },
    },
  },
};

import armani from "../images/xhack/armani.png";
import anthony from "../images/xhack/anthony.png";
import kanav from "../images/xhack/kanav.png";
import kyle from "../images/xhack/kyle.png";
import mark from "../images/xhack/mark.png";
import mirza from "../images/xhack/mirza.png";
import saeed from "../images/xhack/saeed.png";
import bartosz from "../images/xhack/bartosz.png";
import harrison from "../images/xhack/harrison.png";
import mo from "../images/xhack/mo.png";
import tushar from "../images/xhack/tushar.png";
import eric from "../images/xhack/eric.png";
import tristan from "../images/xhack/tristan.png";
import adam from "../images/xhack/adam.png";
import carissa from "../images/xhack/carissa.png";
import don from "../images/xhack/don.png";
import illia from "../images/xhack/illia.png";
import tom from "../images/xhack/tom.png";
import keone from "../images/xhack/keone.png";
import yuriy from "../images/xhack/yuriy.png";

const speakers = [
  {
    image: adam,
    name: "Adam Welc",
    company: ["Mysten Labs"],
    twitter: "https://twitter.com/adam_welc",
  },
  {
    image: armani,
    name: "Armani Ferrante",
    company: ["Backpack"],
    twitter: "https://twitter.com/armaniferrante",
  },
  {
    image: anthony,
    name: "Anthony Ramirez",
    company: ["Jump_"],
    twitter: "https://twitter.com/apram89",
  },
  {
    image: bartosz,
    name: "Bartosz Lipinski",
    company: ["Solana", "BJ Studios"],
    twitter: "https://twitter.com/baalazamon",
  },
  {
    image: carissa,
    name: "Carissa Felger",
    company: ["Gasthalter & Co."],
    twitter: "",
  },
  {
    image: don,
    name: "Don Beaver",
    company: ["Jump_"],
    twitter: "https://twitter.com/jump_",
  },
  {
    image: eric,
    name: "Eric Chen",
    company: ["Injective"],
    twitter: "https://twitter.com/ericinjective",
  },
  {
    image: harrison,
    name: "Harrison Satcher",
    company: ["Dialect"],
    twitter: "https://twitter.com/HarrisonSatcher",
  },
  {
    image: illia,
    name: "Illia Polosukhin",
    company: ["NEAR"],
    twitter: "https://twitter.com/ilblackdragon",
  },
  {
    image: tom,
    name: "InfraRay Tom",
    company: ["Raydium"],
    twitter: "https://twitter.com/0xINFRA",
  },
  {
    image: kanav,
    name: "Kanav Kariya",
    company: ["Jump_"],
    twitter: "https://twitter.com/kanavkariya",
  },
  {
    image: keone,
    name: "Keone Hon",
    company: ["Monad"],
    twitter: "https://twitter.com/keoneHD",
  },
  {
    image: kyle,
    name: "Kyle Samani",
    company: ["Multicoin Capital"],
    twitter: "https://twitter.com/KyleSamani",
  },
  {
    image: mark,
    name: "Mark Wittman",
    company: ["Dialect"],
    twitter: "https://twitter.com/markwittman",
  },
  {
    image: mirza,
    name: "Mirza Uddin",
    company: ["Injective Labs"],
    twitter: "https://twitter.com/TheMirzaUddin",
  },
  {
    image: mo,
    name: "Mo Shaikh",
    company: ["Aptos"],
    twitter: "https://twitter.com/moshaikhs",
  },
  {
    image: saeed,
    name: "Saeed Badreg",
    company: ["Jump_"],
    twitter: "https://twitter.com/shadddowfax",
  },
  {
    image: tushar,
    name: "Tushar Jain",
    company: ["Multicoin Capital"],
    twitter: "https://twitter.com/TusharJain_",
  },
  {
    image: tristan,
    name: "Tristan Yver",
    company: ["FTX.US", "Backpack"],
    twitter: "https://twitter.com/jackshaftoes",
  },
  {
    image: yuriy,
    name: "Yuriy Savchenko",
    company: ["Allbridge"],
    twitter: "https://twitter.com/Allbridge_io",
  },
];

const xhack = ({ location }: PageProps) => {
  return (
    <Layout>
      <MetaData title="xHack" location={location} type="website" />
      <ArrowComponent></ArrowComponent>
      <Box
        component="span"
        sx={{
          position: "absolute",
          left: "65px",
          top: "200px",
          height: "calc(100% - 415px)",
          width: 0,
          borderLeft: "1px solid #4B608D",
          display: { xs: "none", xl: "block" },
          "&:before": {
            content: '""',
            position: "absolute",
            top: "-5px",
            left: "-8px",
            width: "15px",
            height: "5px",
            backgroundColor: "#1CE7C2",
          },
        }}
      />

      <Button
        component={OutboundLink}
        endIcon={<Arrow />}
        href="https://xhack.splashthat.com/"
        variant="outlined"
        sx={{
          backgroundColor: "#08FBCF",
          color: "#0C1F47",
          borderColor: "#08FBCF",
          position: "fixed",
          outline: 0,
          right: "-30px",
          transformOrigin: "center",
          top: 200,
          zIndex: 3,
          transform: "rotate(-90deg)",
          display: { xs: "none", md: "flex" },
          border: "1px solid #08FBCF",
        }}
      >
        Apply
      </Button>
      <Box component="div" sx={{ position: "relative" }}>
        <StaticImage
          src="../images/connect/bricks.svg"
          alt="Jump"
          placeholder="blurred"
          layout="fixed"
          style={{
            position: "absolute",
            left: 0,
            width: "100%",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: -2,
            pointerEvents: "none",
          }}
        />

        <Box
          component="div"
          sx={{
            maxWidth: 760,
            mx: "auto",
            mt: { xs: 12, md: 20 },
            mb: { xs: 6, md: 13 },
            textAlign: "center",
          }}
        >
          <Typography sx={{ mb: 1.2 }} variant="h1">
            {" "}
            <Box component="span" sx={{ color: "#1CE7C2" }}>
              xHack is here.
            </Box>{" "}
            Dev on_
          </Typography>
          <Typography sx={{ maxWidth: 600, mx: "auto", fontSize: 16 }}>
            <strong>
              Discover xHack, the premiere xChain hackathon by Wormhole.
            </strong>{" "}
            Spend four weeks with us at the Pit, our new space at Jump HQ.
          </Typography>
          <Typography sx={{ maxWidth: 600, mx: "auto", fontSize: 16, mt: 3 }}>
            Attendees are welcome to stay the whole time, but must commit to at
            least one week. Trust us. You won’t want to miss this.
          </Typography>
        </Box>
      </Box>

      <Box component="div">
        <Box
          component="div"
          sx={{
            maxWidth: 1082,
            mx: "auto",
            mt: { xs: 12, md: 20 },
            mb: 1,
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(4, 1fr)",
            },
            gap: "10px",
            textAlign: "center",
          }}
        >
          <Box component="div" sx={box}>
            <Typography>
              <strong>Win $250k</strong> in
              <br /> prizes and bounties
            </Typography>
          </Box>
          <Box component="div" sx={box}>
            <Typography>
              We're <strong>open</strong>
              <br /> on weekends
            </Typography>
          </Box>
          <Box component="div" sx={box}>
            <Typography>
              <strong>
                Networking events
                <br />{" "}
              </strong>{" "}
              every Wed and Fri
            </Typography>
          </Box>
          <Box component="div" sx={box}>
            <Typography>
              <strong>Demo days</strong>
              <br /> every Friday{" "}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        component="div"
        sx={{
          position: "relative",
          "&:before": {
            content: `url(${sideBrand})`,
            position: "absolute",
            left: 40,
            top: -80,
            display: { xs: "none", xl: "block" },
            zIndex: -1,
          },
        }}
      />
      <Box component="div" sx={{ maxWidth: 1082, mx: "auto" }}>
        <Box
          component="div"
          sx={{
            background: "#0C1F47",
            border: "1px solid #4B608D",
            padding: { xs: "30px", md: "80px 60px" },
            display: { md: "flex" },
            alignItems: "flex-start",
            mb: 2,
          }}
        >
          <Box
            component="img"
            src={xh}
            alt="xhack"
            loading="lazy"
            sx={{
              flex: "0 0 auto",
              marginRight: "80px",
              marginBottom: "40px",
            }}
          />
          <Box component="div">
            <Typography
              variant="h3"
              sx={{
                mb: 4.2,
              }}
            >
              Calling all builders.
            </Typography>
            <Typography>
              On September 26th, we’ll host our inaugural event in the Pit - an
              xChain hackathon by Wormhole, where natives from various chains
              will come together to build. You’ll have access to technological
              resources, office hours with some of Jump Crypto’s top minds,
              seminars, meals, and enough coffee to keep you going through the
              night. Help build the future of cross chain at xHack.
            </Typography>
            <Button
              component={OutboundLink}
              endIcon={<Arrow />}
              href="https://xhack.splashthat.com/"
              variant="outlined"
              sx={{
                backgroundColor: "#08FBCF",
                color: "#0C1F47",
                borderColor: "#08FBCF",
                mt: 4.5,
              }}
            >
              Apply
            </Button>
          </Box>
        </Box>
        <Box component="div" sx={{ pt: 7, pb: 6 }}>
          <Typography variant="h2" sx={{ textAlign: "center" }}>
            Featured Speakers
          </Typography>
          <Box
            component="div"
            sx={{
              maxWidth: 1082,
              mx: "auto",
              mt: 3.2,
              display: { xs2: "grid" },
              gridTemplateColumns: {
                xs2: "repeat(2, 1fr)",
                sm: "repeat(3, 1fr)",
                md: "repeat(5, 1fr)",
              },
              gap: "14px",
            }}
          >
            {speakers.map((s) => (
              <Box component="div" key={s.name} sx={card}>
                <Box
                  component="img"
                  src={s.image}
                  alt={s.name}
                  sx={{
                    position: "absolute",
                    right: 0,
                    bottom: 0,
                    objectFit: "contain",
                  }}
                />
                <Box component="div">
                  <Typography variant="h6">{s.name}</Typography>
                  <Typography>
                    {s.company.map((c, index) => (
                      <Box component="span">{c}</Box>
                    ))}
                  </Typography>
                </Box>
                {s.twitter && (
                  <Link href={s.twitter} target="_blank">
                    <Twitter />
                  </Link>
                )}
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          component="div"
          sx={{
            display: "grid",
            gridTemplateColumns: { md: "repeat(2, 1fr)" },
            gap: 1,
            mb: 2,
          }}
        >
          <Link
            component={OutboundLink}
            sx={cell}
            href="https://goo.gl/maps/EwJbCfHVE2Uj97ZW8"
            target="_blank"
          >
            <Location />
            <Typography>At the Pit, 600 W Chicago Ave.</Typography>
          </Link>
          <Box component="div" sx={cell}>
            <Calendar />
            <Typography>Sept 26th to Oct 21st</Typography>
          </Box>
        </Box>
        <Box
          component="div"
          sx={{
            display: "grid",
            gridTemplateColumns: { md: "repeat(2, 1fr)" },
            gap: 1,
            mb: 2,
          }}
        >
          <StaticImage
            src="../images/x1.jpg"
            layout="fullWidth"
            placeholder="blurred"
            alt="Pit"
            style={{ border: "1px solid #4B608D" }}
          />
          <StaticImage
            src="../images/x2.jpg"
            layout="fullWidth"
            placeholder="blurred"
            alt="Pit"
            style={{ border: "1px solid #4B608D" }}
          />
        </Box>

        <Box
          component="div"
          sx={{
            backgroundColor: "#091A3D",
            border: "1px solid #4B608D",
            mt: { xs: 2, md: 14 },
            px: { xs: 3, md: 6 },
            py: { xs: 3, md: 6 },
            maxWidth: { md: 550 },
            position: "relative",
            mx: "auto",
            zIndex: 2,
          }}
        >
          <Typography variant="h4" sx={{ pt: 1.4, pb: 3 }}>
            What to expect at xHack
          </Typography>
          <Typography>
            xHack attendees can expect programming on the following topics.
            While all weeks are not mandatory, guests are expected to stay for
            at least one week and absolutely welcome to stay longer.
          </Typography>
        </Box>

        <Box
          component="div"
          sx={{
            mt: { md: 12 },
            display: "flex",
            flexWrap: "wrap",
            position: "relative",
            "&:before": {
              content: '""',
              position: "absolute",
              left: "50%",
              top: -100,
              height: "calc(93% + 80px)",
              borderRight: "1px solid #4B608D",
              display: { xs: "none", md: "block" },
            },
            "& > div": {
              width: { xs: "100%", md: "50%" },
              position: "relative",
              mb: { xs: 5, md: 8 },
            },
            "& > div:nth-of-type(even)": {
              mb: { md: 18 },
            },
            "& > div:nth-of-type(even) > div": {
              ml: "auto",
            },
            "& > div:nth-of-type(odd) > div": {
              transform: { md: "translateY(-260px)" },
            },
          }}
        >
          <Box component="div" />
          <Box component="div">
            <Box
              sx={{
                position: "absolute",
                left: "-9px",
                top: "-140px",
                display: { xs: "none", md: "block" },
              }}
              component="img"
              src={c1}
              alt=""
            />
            <Box component="div" sx={cardStyle}>
              <Box sx={cardIndex} component="span">
                Week 1
              </Box>
              <Typography variant="h4" sx={{ pt: 1.4, pb: 3 }}>
                Where are we today?
              </Typography>
              <Box component="div" sx={{ "& p": { mb: 2 } }}>
                <Typography>
                  The program kicks off with how Wormhole got here and covers
                  topics like:
                </Typography>
                <Typography>
                  “What Exists Right Now?” <br />
                  “Is There Such Thing as Too Much DeFi?” <br />
                  “How to Build xApps” <br />
                  “Building True xChain Utility”
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box component="div">
            <Box
              sx={{
                position: "absolute",
                right: "-12px",
                top: "-490px",
                display: { xs: "none", md: "block" },
              }}
              component="img"
              src={c2}
              alt=""
            />
            <Box component="div" sx={cardStyle}>
              <Box sx={cardIndex} component="span">
                Week 2
              </Box>
              <Typography variant="h4" sx={{ pt: 1.4, pb: 3 }}>
                Cross-chain infrastructure
              </Typography>
              <Box component="div" sx={{ "& p": { mb: 2 } }}>
                <Typography>
                  This track will focus on the nuts and bolts of the
                  infrastructure behind everything in the xChain world.{" "}
                </Typography>
                <Typography>
                  There will be a variety of panels and guests such as: <br />
                  / RPC node providers, <br />
                  / Guardians, and <br />/ Engineers working on automation.
                </Typography>
                <Typography>
                  Plus, there will be plenty of information on emerging arenas:{" "}
                  <br />
                  {"> xApps"} <br />
                  {"> xDEXs"} <br />
                  {"> xGovernance."}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box component="div">
            <Box
              sx={{
                position: "absolute",
                left: "-9px",
                top: "-200px",
                display: { xs: "none", md: "block" },
              }}
              component="img"
              src={c3}
              alt=""
            />
            <Box component="div" sx={cardStyle}>
              <Box sx={cardIndex} component="span">
                Week 3
              </Box>
              <Typography variant="h4" sx={{ pt: 1.4, pb: 3 }}>
                Prod dev for devs
              </Typography>
              <Box component="div" sx={{ "& p": { mb: 2 } }}>
                <Typography>
                  This week, we’ll focus on everything you need to know to be
                  successful (other than coding!), including:{" "}
                </Typography>
                <Typography>
                  “Marketing and PR Help” <br />
                  “Trading and Liquidity Primers” <br />
                  “Tokenomics Best Practices and Theory” <br />
                  “Testing and Rollout Plans”
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box component="div">
            <Box
              sx={{
                position: "absolute",
                right: "-12px",
                top: "-490px",
                display: { xs: "none", md: "block" },
              }}
              component="img"
              src={c2}
              alt=""
            />
            <Box component="div" sx={cardStyle}>
              <Box sx={cardIndex} component="span">
                Week 4
              </Box>
              <Typography variant="h4" sx={{ pt: 1.4, pb: 3 }}>
                The future
              </Typography>
              <Box component="div" sx={{ "& p": { mb: 2 } }}>
                <Typography>
                  Finally, we’ll turn to the future of Web3 with a series or
                  provocative topics including:
                </Typography>
                <Typography>
                  "Where Does xChain Go from Here?” <br />
                  “Backpack, xNFTs, and the Next Gen” <br />
                  “xPloring Gaming and Hardware Apps” <br />
                  “What Is Holding Us Back?”
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              position: "absolute",
              right: "calc(50% - 12px)",
              bottom: "130px",
              display: { xs: "none", md: "block" },
            }}
            component="img"
            src={c4}
            alt=""
          />
        </Box>

        <Box
          component="div"
          sx={{
            padding: "56.25% 0 0 0",
            position: "relative",
            overflow: "hidden",
            border: "1px solid #4B608D",
            mb: { xs: 8, md: 17 },
          }}
        >
          <iframe
            loading="lazy"
            src="https://player.vimeo.com/video/725060552?&title=0&byline=0&portrait=0"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            frameBorder={0}
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          />
        </Box>

        <Typography variant="h2" sx={{ textAlign: "center", mb: 8 }}>
          Partners
        </Typography>

        <Box
          component="div"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flexStart",
            my: 9,
            mx: "auto",
            maxWidth: 934,
          }}
        >
          {projects.map((item: Project) => (
            <Box
              component="div"
              key={item.url}
              sx={{
                width: { xs: "50%", sm: "33.33%", md: "25%" },
                px: { xs: 1, sm: 2 },
                mb: 4,
              }}
            >
              <Link
                sx={projectBox}
                component={OutboundLink}
                href={item.url}
                target="_blank"
              >
                <Box
                  component="img"
                  src={item.logo}
                  alt={item.name}
                  sx={projectBoxLogo}
                  loading="lazy"
                />
              </Link>
              <Typography sx={{ fontSize: 13, mt: 0.5 }}>
                {item.name}
              </Typography>
            </Box>
          ))}
        </Box>

        <Box
          component="div"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flexStart",
            my: 9,
            mx: "auto",
            maxWidth: 934,
          }}
        >
          {projects2.map((item: Project) => (
            <Box
              component="div"
              key={item.url}
              sx={{
                width: { xs: "50%", sm: "33.33%", md: "25%" },
                px: { xs: 1, sm: 2 },
                mb: 4,
              }}
            >
              <Link
                sx={projectBox}
                component={OutboundLink}
                href={item.url}
                target="_blank"
              >
                <Box
                  component="img"
                  src={item.logo}
                  alt={item.name}
                  sx={projectBoxLogo}
                  loading="lazy"
                />
              </Link>
              <Typography sx={{ fontSize: 13, mt: 0.5 }}>
                {item.name}
              </Typography>
            </Box>
          ))}
        </Box>

        <Box
          component="div"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flexStart",
            my: 9,
            mx: "auto",
            maxWidth: 934,
          }}
        >
          {projects3.map((item: Project) => (
            <Box
              component="div"
              key={item.url}
              sx={{
                width: { xs: "50%", sm: "33.33%", md: "25%" },
                px: { xs: 1, sm: 2 },
                mb: 4,
              }}
            >
              {item.url ? (
                <Link
                  sx={projectBox}
                  component={OutboundLink}
                  href={item.url}
                  target="_blank"
                >
                  <Box
                    component="img"
                    src={item.logo}
                    alt={item.name}
                    sx={projectBoxLogo}
                    loading="lazy"
                  />
                </Link>
              ) : (
                <Box component="span" sx={projectBox}>
                  <Box
                    component="img"
                    src={item.logo}
                    alt={item.name}
                    sx={projectBoxLogo}
                    loading="lazy"
                  />
                </Box>
              )}
              <Typography sx={{ fontSize: 13, mt: 0.5 }}>
                {item.name}
              </Typography>
            </Box>
          ))}
        </Box>

        <Box
          component="div"
          sx={{
            background: "#0C1F47",
            border: "3px solid #1CE7C2",
            padding: "80px 60px",
            position: "relative",
            mb: { xs: 10, md: 20 },
          }}
        >
          <StaticImage
            src="../images/pitbanner.jpg"
            alt="Introducing the Pit."
            placeholder="blurred"
            layout="fullWidth"
            objectFit="cover"
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
            }}
          />
          <Box
            component="img"
            src={pit}
            alt="Introducing the Pit."
            loading="lazy"
            sx={{
              position: { xs: "relative", md: "absolute" },
              right: { md: "10%" },
              top: { md: "50%" },
              transform: { md: "translateY(-50%)" },
              mb: { xs: 3, md: 0 },
            }}
          />
          <Typography variant="h2" sx={{ position: "relative", zIndex: 2 }}>
            Introducing the Pit.
          </Typography>
          <Typography
            sx={{
              maxWidth: 280,
              mt: 1.6,
              mb: 4,
              position: "relative",
              zIndex: 2,
            }}
          >
            Check out our new space where we are changing the face of Web3.
          </Typography>
          <Button
            component={RouterLink}
            endIcon={<Arrow />}
            to="/thepit"
            variant="outlined"
            sx={{
              backgroundColor: "#08FBCF",
              color: "#0C1F47",
              borderColor: "#08FBCF",
              position: "relative",
              zIndex: 2,
            }}
          >
            .Learn More
          </Button>
        </Box>
      </Box>

      <StaticImage
        src="../images/brandLines.svg"
        alt=""
        placeholder="blurred"
        style={{
          position: "absolute",
          right: "0",
          bottom: "0",
          zIndex: -1,
          pointerEvents: "none",
        }}
      />
    </Layout>
  );
};

export default xhack;
