import React from "react";
import { Box, Link, Typography } from "@mui/material";
import { OutboundLink } from "gatsby-plugin-google-gtag";

export const projectBox = {
  textAlign: "center",
  background: "#091A3D",
  border: "1px solid #4B608D",
  position: "relative",
  transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
  height: "105px",
  display: "flex",
  p: 1.5,
  alignItems: "center",
  justifyContent: "center",
  "&:before": {
    content: '""',
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "#091A3D",
    border: "1px solid #4B608D",
    left: "10px",
    top: "-10px",
    zIndex: -1,
    transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
  },
  "&:hover": {
    boxShadow: "0 0 20px 0 rgba(255, 255, 255, .3)",
    "&:before": {
      left: "0",
      top: "0",
    },
  },
};

export const projectBoxLogo = {
  display: "block",
  maxHeight: "44px",
  maxWidth: "110px",
  width: "auto",
};

export interface Project {
  url: string;
  logo: string;
}

import btse from "../../images/index/btse.svg";
import human from "../../images/index/human.svg";
import serum from "../../images/index/serum.svg";
import pyth from "../../images/index/pyth.svg";
import zero1 from "../../images/index/zero1.svg";
import acala from "../../images/index/acala.svg";
import backpack from "../../images/index/backpack.svg";
import certora from "../../images/index/certora.svg";
import injective from "../../images/index/injective.svg";
import kukoin from "../../images/index/kukoin.svg";
import metaplex from "../../images/index/metaplex.svg";
import neon from "../../images/index/neon.svg";
import near from "../../images/index/near.svg";
import hashflow from "../../images/xhack/hashflow.svg";
import aptos from "../../images/xhack/aptos.svg";
import argent from "../../images/index/argent.svg";
import chiliz from "../../images/index/chiliz.svg";
import p2p from "../../images/index/p2p.svg";
import paradigm from "../../images/index/paradigm.svg";
import xpla from "../../images/index/xpla.svg";
import ztx from "../../images/index/ztx.svg";
import commas3 from "../../images/index/commas3.svg";
import bitoasis from "../../images/index/bitoasis.svg";
import bitpanda from "../../images/index/bitpanda.svg";
import busha from "../../images/index/busha.svg";
import fomopay from "../../images/index/fomopay.svg";
import klaytn from "../../images/index/klaytn.svg";
import msafe from "../../images/index/msafe.svg";
import mystenlabs from "../../images/index/mystenlabs.svg";
import ulvetanna from "../../images/index/ulvetanna.svg";
import sei from "../../images/index/sei.svg";
import synthetix from "../../images/index/synthetix.svg";
import turbos from "../../images/index/turbos.png";
import squads from "../../images/index/squads.svg";
import altlayer from "../../images/index/altlayer.svg";
import polygon from "../../images/index/polygon.svg";
import solana from "../../images/index/solana.svg";
import sui from "../../images/index/sui.svg";
import wormhole from "../../images/index/wormhole.svg";

const projects = [
  {
    logo: zero1,
    url: "https://www.0x.org/",
  },
  {
    logo: commas3,
    url: "https://3commas.io/",
  },
  {
    logo: acala,
    url: "https://acala.network/",
  },
  {
    logo: altlayer,
    url: "https://www.altlayer.io/",
  },
  {
    logo: aptos,
    url: "https://aptoslabs.com/",
  },
  {
    logo: argent,
    url: "https://www.argent.xyz/",
  },
  {
    logo: backpack,
    url: "https://www.backpack.app/",
  },
  {
    logo: bitoasis,
    url: "https://www.bitoasis.net/",
  },
  {
    logo: bitpanda,
    url: "https://www.bitpanda.com/",
  },
  {
    logo: busha,
    url: "https://www.busha.co/",
  },
  // {
  //   logo: btse,
  //   url: "https://www.btse.com/",
  // },
  {
    logo: certora,
    url: "https://www.certora.com/",
  },
  {
    logo: chiliz,
    url: "https://www.chiliz.com/en/",
  },
  {
    logo: fomopay,
    url: "https://www.fomopay.com/",
  },
  {
    logo: hashflow,
    url: "https://www.hashflow.com/",
  },
  // {
  //   logo: human,
  //   url: "https://www.humanprotocol.org/",
  // },
  {
    logo: injective,
    url: "https://injective.com/",
  },
  {
    logo: klaytn,
    url: "https://klaytn.foundation/",
  },
  // {
  //   logo: kukoin,
  //   url: "https://www.kucoin.com/",
  // },
  {
    logo: metaplex,
    url: "https://www.metaplex.com/",
  },
  {
    logo: msafe,
    url: "https://www.m-safe.io/",
  },
  {
    logo: mystenlabs,
    url: "https://www.mystenlabs.com/",
  },
  {
    logo: neon,
    url: "https://neon-labs.org/",
  },
  {
    logo: p2p,
    url: "https://p2p.org/",
  },
  // {
  //   logo: near,
  //   url: 'https://near.org/'
  // },
  {
    logo: paradigm,
    url: "https://www.paradigm.co/",
  },
  {
    logo: polygon,
    url: "https://polygon.technology/",
  },

  {
    logo: pyth,
    url: "https://pyth.network/",
  },
  {
    logo: squads,
    url: "https://squads.so/",
  },
  {
    logo: sei,
    url: "https://seinetwork.io/",
  },
  {
    logo: solana,
    url: "https://solana.com/",
  },
  {
    logo: sui,
    url: "https://sui.io/",
  },
  {
    logo: synthetix,
    url: "https://synthetix.io/",
  },
  {
    logo: turbos,
    url: "https://turbos.finance/",
  },
  {
    logo: ulvetanna,
    url: "https://ulvetanna.io/",
  },
  {
    logo: wormhole,
    url: "https://www.wormhole.com/",
  },
  {
    logo: xpla,
    url: "https://xpla.io/",
  },
  {
    logo: ztx,
    url: "https://ztx.io/",
  },
];

const Investments = () => {
  return (
    <Box component="div" sx={{ position: "relative" }} id="featured-projects">
      <Box
        component="div"
        sx={{
          maxWidth: 1044,
          mx: "auto",
          pt: 2,
          pb: 4.5,
          mb: { xs: 6, sm: 18 },
        }}
      >
        <Typography variant="h2" sx={{ mb: 4.8 }}>
          Investments
        </Typography>
        <Box
          component="div"
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(2, 1fr)",
              sm: "repeat(3, 1fr)",
              md: "repeat(4, 1fr)",
            },
            gap: { xs: 2, md: 4.8 },
          }}
        >
          {projects.map((item: Project) => (
            <Box component="div" key={item.url}>
              <Link
                sx={projectBox}
                component={OutboundLink}
                href={item.url}
                target="_blank"
              >
                <Box
                  component="img"
                  src={item.logo}
                  sx={projectBoxLogo}
                  loading="lazy"
                />
              </Link>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Investments;
